.about {
    padding: 2rem;
    text-align: center;
}

    .about h2 {
        font-size: 2rem;
        margin-bottom: 1rem;
    }

    .about p {
        font-size: 1.1rem;
        line-height: 1.5;
    }

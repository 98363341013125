.projects {
    padding: 2rem;
    text-align: center;
}

    .projects h2 {
        font-size: 2rem;
        margin-bottom: 1rem;
    }

.projectList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.project {
    background-color: #f4f4f4;
    border-radius: 8px;
    margin: 1rem;
    padding: 1rem;
    width: 300px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

    .project h3 {
        font-size: 1.5rem;
        margin: 0.5rem 0;
    }

    .project p {
        font-size: 1rem;
    }

    .project a {
        color: #007BFF;
        text-decoration: none;
    }

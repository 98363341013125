.header {
    background-color: #282c34;
    color: white;
    padding: 2rem;
    text-align: center;
}

    .header h1 {
        font-size: 3rem;
        margin: 0;
    }

    .header p {
        font-size: 1.2rem;
    }

body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
}

nav {
    position: fixed;
    width: 100%;
    background-color: #333;
    padding: 10px 0;
}

    nav ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        text-align: center;
    }

        nav ul li {
            display: inline;
            margin: 0 15px;
        }

            nav ul li a {
                color: white;
                text-decoration: none;
                font-size: 18px;
            }

                nav ul li a:hover {
                    text-decoration: underline;
                }

section {
    padding: 60px 20px;
}

.about {
    background-color: #f4f4f4;
}

.projects {
    background-color: #e2e2e2;
}

.contact {
    background-color: #d0d0d0;
}
